import { ResizeObserver } from '@juggle/resize-observer';
import {
  connectToIframe,
  connectToParentPage,
} from '@purple-dot/libraries/src/iframe-connect';

import { getPlacementContainer, getPlacementIframe } from './placements';

type IframeStyle = {
  width?: string;
  height?: string;
  initialHeight?: string;
  display?: string;
};

export function injectPlacementIframe({
  hostURL,
  src,
  style,
  dataRequestHandlers,
  placementType,
  instanceId,
  listenForResize = true,
  allowMultiple = false,
}: {
  hostURL: string;
  src: string;
  style: IframeStyle;
  dataRequestHandlers?: Record<string, (...args: any[]) => any>;
  placementType: string;
  instanceId?: number;
  listenForResize?: boolean;
  allowMultiple?: boolean;
}) {
  if (getPlacementIframe({ instanceId, placementType }) && !allowMultiple) {
    // biome-ignore lint/suspicious/noConsole: log
    console.warn(
      'PurpleDot.load(): Placement already configured, use update() to update it.'
    );
    return Promise.resolve();
  }

  const containerElement = getPlacementContainer({ instanceId, placementType });
  if (!(containerElement instanceof HTMLElement)) {
    return Promise.resolve();
  }

  const autoInstanceId = 1;
  if (!instanceId) {
    containerElement.dataset.purpleDotInstanceId = `${autoInstanceId}`;
  }

  const iframe = createPlacementIframe(src, style, {
    placementType,
    instanceId: `${instanceId || autoInstanceId}`,
  });
  updateIframeURL(iframe, src);
  containerElement.appendChild(iframe);

  return connectToIframe({ hostURL, iframe, dataRequestHandlers })
    .then((child: any) => {
      const container = getPlacementContainer({ instanceId, placementType });
      if (!(container instanceof HTMLElement)) {
        return;
      }

      showIfHidden(container);

      if (listenForResize) {
        child.on(
          'window-size-changed',
          ({ width, height }: { width: number; height: number }) => {
            if (height) {
              iframe.style.height = `${height}px`;
            }

            // Only apply width changes to inline-block iframes
            if (width && iframe.style.display === 'inline-block') {
              iframe.style.width = `${width}px`;
            }
          }
        );
      }

      return { childBridge: child, iframe };
    })
    .catch((err: any) => {
      // biome-ignore lint/suspicious/noConsole: log
      console.error(err);
      const container = getPlacementContainer({ instanceId, placementType });
      if (!(container instanceof HTMLElement)) {
        return;
      }

      hide(container);

      const { reason, message } = err.data;
      // biome-ignore lint/suspicious/noConsole: log
      console.info(
        `Purple Dot placement '${placementType}' not shown, reason: '${reason}: ${message}'`
      );

      throw err;
    });
}

export function connectToPlacementParent(...args: any[]) {
  return connectToParentPage(...args).then((parentBridge: any) => {
    const elem = document.body;

    const resizeObserver = new ResizeObserver(() => {
      parentBridge.emit('window-size-changed', {
        width: elem.offsetWidth,
        height: elem.offsetHeight,
      });
    });

    resizeObserver.observe(elem);

    return parentBridge;
  });
}

function createPlacementIframe(
  src: string,
  style: IframeStyle,
  { placementType, instanceId }: { placementType: string; instanceId: string }
) {
  const iframe = document.createElement('iframe');
  iframe.dataset.placementType = placementType;
  iframe.dataset.instanceId = instanceId;
  iframe.setAttribute('scrolling', 'no');
  iframe.setAttribute('class', 'purple-dot-frame');
  iframe.style.border = 'none';
  iframe.style.width = style?.width || '100%';
  iframe.style.height =
    (style && (style.initialHeight || style.height)) || '54px';
  iframe.style.display = style?.display || 'block';
  updateIframeURL(iframe, src);
  return iframe;
}

function updateIframeURL(ifrm: HTMLIFrameElement, url: string) {
  ifrm.dataset.loaded = 'false';
  ifrm.addEventListener('load', () => {
    // Used by Cypress when running tests to check if iframe already loaded
    ifrm.dataset.loaded = 'true';
  });

  setIframeSrc(ifrm, url);
}

function setIframeSrc(ifrm: HTMLIFrameElement, url: string) {
  ifrm.setAttribute('src', url);
}

function showIfHidden(el: HTMLElement | null) {
  if (el?.style.display === 'none' && el.dataset.originalDisplay) {
    el.style.display = el.dataset.originalDisplay;
  }
}

function hide(el: HTMLElement) {
  if (!el.dataset.originalDisplay) {
    el.dataset.originalDisplay = window.getComputedStyle(el).display;
  }

  el.style.display = 'none';
}
