import {
  filterObj,
  listOfValues,
} from '@purple-dot/libraries/src/language-helpers/objects';

class CartButtonPositions {
  constructor(placements) {
    this.placements = placements;
  }

  placeBelowCartButton(elem, { desktopWidth } = {}) {
    const cartButton = this._chooseVisibleCartButton();
    if (!cartButton) {
      return;
    }

    const clientRect = cartButton.getBoundingClientRect();
    const bodyRect = document.body.getBoundingClientRect();
    const { bottom, right } = clientRect;
    const rightCoord = bodyRect.width - right;

    const width = window.innerWidth;
    const isMobile = width < 750;
    elem.style.top = `${bottom}px`;
    elem.style.right = isMobile ? '0px' : `${rightCoord}px`;
    elem.style.left = isMobile ? '0px' : 'auto';
    elem.style.width = isMobile ? '100%' : desktopWidth;
  }

  _chooseVisibleCartButton() {
    const allCartButtons = this._allCartButtons();

    const visibleCartButtons = allCartButtons.filter((button) =>
      button.isVisible()
    );
    if (visibleCartButtons.length === 1) {
      return visibleCartButtons[0];
    }

    if (visibleCartButtons.length > 1) {
      const buttonsInViewport = visibleCartButtons.filter((button) =>
        button.inViewport()
      );
      if (buttonsInViewport.length >= 1) {
        return buttonsInViewport[0];
      }

      return visibleCartButtons[0];
    }

    return allCartButtons[0];
  }

  _allCartButtons() {
    const cartButtonObj = filterObj(
      (k) => k.includes('cart-button'),
      this.placements
    );
    return listOfValues(cartButtonObj);
  }
}

export default CartButtonPositions;
