import { injectOverlayIframe } from '@purple-dot/libraries/src/iframe-connect/overlay-manager';

import { createURL } from '@purple-dot/libraries/src/url';
import trackEvent from '../../track-event';

class NotifyMe {
  constructor(hostUrl, apiKey, locale) {
    this.hostURL = hostUrl;
    this.apiKey = apiKey;
    this.locale = locale;
  }

  open({ productId, skuId, releaseId }) {
    injectOverlayIframe({
      hostURL: this.hostURL,
      id: 'purple-dot-notify-me',
      src: createURL({
        host: this.hostURL,
        path: '/embedded-checkout/notify-me',
        queryParams: {
          apiKey: this.apiKey,
          productId,
          skuId,
          releaseId,
          locale: this.locale,
        },
      }),
    }).then(() => {
      trackEvent('placement_loaded', { placementType: 'notify-me' });
    });
  }
}

export default NotifyMe;
