import { normalizedHashCode } from '@purple-dot/libraries/src/language-helpers/strings';

export type FlagConfig = {
  flag: string;
  variations: {
    variation: string;
    weight: number;
  }[];
};

export class FeatureFlags {
  private flagConfig: FlagConfig[];
  private readonly userId: string;

  constructor(flagConfig: FlagConfig[], userId: string) {
    this.userId = userId;
    assert(this.userId.length > 0, 'Must provide a userId string');

    this.flagConfig = flagConfig;
  }

  variation(flag: string): string | undefined {
    const config = this.flagConfig.find((f) => f.flag === flag);

    if (!config) {
      return undefined;
    }

    const rand = this.randomVariationForFlag(flag);
    return weightedChoice(config.variations, rand);
  }

  allVariations() {
    return Object.fromEntries(
      this.flagConfig.map((f) => [f.flag, this.variation(f.flag)] as const)
    );
  }

  updateFlagConfig(config: FlagConfig) {
    const idx = this.flagConfig.findIndex((f) => f.flag === config.flag);
    if (idx >= 0) {
      this.flagConfig[idx] = config;
    } else {
      this.flagConfig.push(config);
    }
  }

  resetFlagConfig() {
    this.flagConfig = [];
  }

  private randomVariationForFlag(flag: string) {
    const randSeed = `${this.userId}:${flag}`;
    return normalizedHashCode(randSeed);
  }
}

function weightedChoice(
  choices: { variation: string; weight: number }[],
  r: number
) {
  const totalWeight = sum(choices.map(({ weight }) => weight));
  let s = r * totalWeight;

  for (const { variation, weight } of choices) {
    if (s < weight) {
      return variation;
    }
    s -= weight;
  }
}

function sum(xs: number[]) {
  return xs.reduce((acc, x) => acc + x, 0);
}

function assert(exp: boolean, msg: string) {
  if (!exp) {
    throw new Error(msg);
  }
}
