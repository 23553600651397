import { FeatureFlags } from '@purple-dot/shopify-script/src/feature-flags';
import deviceId from './device-id';

export function disallowCheckout({ shopifyCart }) {
  if (!shopifyCart) {
    return false;
  }

  const { items } = shopifyCart;

  const cartHasNoPreorderItems = !items.some(isPreorderItem);
  if (cartHasNoPreorderItems) {
    return false;
  }

  const cartIsMixed = !items.every(isPreorderItem);
  if (cartIsMixed) {
    return true;
  }

  const someItemsNotCompatibleWithNativeCheckout = items.some(
    (item) => !isItemCompatibleWithNativeCheckout(item)
  );

  if (someItemsNotCompatibleWithNativeCheckout) {
    return true;
  }

  const featureFlags = new FeatureFlags(
    window.PurpleDotConfig?.flags ?? [],
    deviceId().deviceId
  );
  const variation = featureFlags.variation('NATIVE_CHECKOUT');

  if (variation === 'purple_dot') {
    return true;
  }
  if (variation === 'native') {
    return false;
  }

  return false;
}

function isPreorderItem(item) {
  return !!item.properties?.__releaseId;
}

function isItemCompatibleWithNativeCheckout(item) {
  if (
    item.properties &&
    '__pdCheckoutRequired' in item.properties &&
    item.selling_plan_allocation?.selling_plan?.id
  ) {
    const checkoutRequired = item.properties.__pdCheckoutRequired;
    const isCompatible =
      checkoutRequired === false ||
      checkoutRequired === 'false' ||
      checkoutRequired === undefined;
    return isCompatible;
  }

  return false;
}
