import { createURL } from '@purple-dot/libraries/src/url';

class PlacementSrc {
  constructor(hostURL, apiKey, requestData, options) {
    if (!(hostURL && apiKey && requestData)) {
      throw new Error('Could not create PlacementSrc');
    }

    this.hostURL = hostURL;
    this.apiKey = apiKey;

    const { placementType, instanceId, ...rest } = requestData;
    this.placementType = placementType;
    this.instanceId = instanceId;
    this.options = {
      ...(rest || {}),
      ...options,
    };
  }

  update(newRequestData) {
    const { ...newOptions } = newRequestData;
    return createURL({
      host: this.hostURL,
      path: `/embedded/placements/${this.placementType}`,
      queryParams: {
        ...this.options,
        ...newOptions,
        apiKey: this.apiKey,
        instanceId: this.instanceId,
        debug: window.__PD_DEBUG === true || undefined,
        locale: this.options.locale,
      },
      hash: JSON.stringify({ parentPageUrl: window.location.href }),
    });
  }

  url() {
    return createURL({
      host: this.hostURL,
      path: `/embedded/placements/${this.placementType}`,
      queryParams: {
        ...this.options,
        apiKey: this.apiKey,
        instanceId: this.instanceId,
        debug: window.__PD_DEBUG === true || undefined,
        locale: this.options.locale,
      },
      hash: JSON.stringify({ parentPageUrl: window.location.href }),
    });
  }
}

export default PlacementSrc;
