import ShopifyBehaviour from '../../platforms/shopify';

/**
 * Button
 *
 * Represents the Iframe Button
 */

class Button {
  constructor(
    simplePlacement,
    options,
    staticComponents,
    publicEvents,
    impressionTracker
  ) {
    this.simplePlacement = simplePlacement;
    this.style = options ? options.style || {} : {};
    this.staticComponents = staticComponents;
    this.impressionTracker = impressionTracker;
    this.publicEvents = publicEvents;
  }

  mountInto(container) {
    this.simplePlacement.iframe.style((elem) => {
      elem.style.width = this.style.width || '100%';
      elem.style.height = this.style.height || '54px';
      elem.style.display = this.style.display || 'block';
    });

    this.simplePlacement.iframe.attachListener([
      ['button-clicked', (d) => this._buttonClicked(d)],
      ['combined-cart-button-clicked', (d) => this._combinedButtonClicked(d)],
      [
        'variant-impression',
        (data) => {
          const handle = this.impressionTracker(
            this.simplePlacement.iframe._iframe
          );
          handle(data);
        },
      ],
      ['value-prop-clicked', (data) => this._learnMoreClicked(data)],
      [
        'notify-me-clicked',
        (data) => this.staticComponents.notifyMe.open(data),
      ],
    ]);

    this.simplePlacement.mountInto(container);
  }

  update({ ...options }) {
    const ifrm = this.simplePlacement.iframe._iframe;
    const url = new URL(ifrm.src);
    const urlProductId = new URLSearchParams(url.search).get('productId');
    const hasLoaded = this.simplePlacement.iframe.hasLoaded();
    const isProductIdTheSame = `${urlProductId}` === `${options.productId}`;
    const updateHasProductId = !!options.productId;

    if (hasLoaded && (!updateHasProductId || isProductIdTheSame)) {
      this.simplePlacement.iframe.postMessage('UPDATE', {
        sku: options.sku ? `${options.sku}` : null,
        skuExternalId: options.skuId ? `${options.skuId}` : null,
        lineItemProperties: options.lineItemProperties,
      });
    } else {
      this.simplePlacement.update({ ...options });
    }
  }

  _buttonClicked(data) {
    this.publicEvents.buttonClicked(data);
  }

  _combinedButtonClicked(data) {
    if (window.pdListeners?.addItemToCart) {
      window.pdListeners.addItemToCart({
        items: [
          {
            id: data.externalId,
            properties: {
              ...ShopifyBehaviour.hideProperties({
                releaseId: data.releaseId,
                presaleId: data.presaleId,
              }),
              'Purple Dot Pre-order': data.shipDates,
            },
          },
        ],
      });
    } else {
      // biome-ignore lint/suspicious/noConsole: log
      console.error('window.pdListeners.addItemToCart is not set');
    }
  }

  _learnMoreClicked(data) {
    this.staticComponents.learnMore.open(data);
    this.publicEvents.learnMoreClicked(data);
  }
}

export default Button;
