import { Cart, CartItem } from '@purple-dot/browser/cart';
import { ShopifyAJAXCart } from '@purple-dot/browser/shopify-ajax-cart';
import { injectOverlayIframe } from '@purple-dot/libraries/src/iframe-connect/overlay-manager';
import { createURL } from '@purple-dot/libraries/src/url';
import * as events from './events';
import { ShopifyRoutesAdapter } from './routes-adapter';

/**
 * CombinedCheckoutIframe
 *
 * Loaded on the cart page to provide
 * a page for us to call our checkout from our domain
 * as not to need to expose the checkout with cors
 */

export class CombinedCheckoutIframe {
  constructor(
    private hostURL: string,
    private apiKey: string,
    private currency: string,
    private locale: string,
    private getCustomerData?: () => Promise<object>
  ) {}

  iframeURL() {
    return createURL({
      host: this.hostURL,
      path: '/embedded-checkout/combined-checkout',
      queryParams: {
        apiKey: this.apiKey,
        locale: this.locale,
      },
    });
  }

  mount({
    id,
    goAffProRef,
    cartType,
    items,
    code,
    shopifyCart,
    shopifyCartString,
    shopifyCartSignature,
    emitPublicEvents = true,
  }: {
    id: string;
    goAffProRef?: string;
    cartType: 'ajax' | 'storefront';
    items?: any[];
    code?: string;
    shopifyCart?: any;
    shopifyCartString?: string;
    shopifyCartSignature?: string;
    emitPublicEvents?: boolean;
  }) {
    void injectOverlayIframe({
      id: 'checkout-iframe',
      hostURL: this.hostURL,
      src: this.iframeURL(),
    }).then((bridge) => {
      bridge.postMessage('ITEMS', {
        id,
        goAffProRef,
        cartType,
        items,
        currency: this.currency,
        code,
        shopifyCart,
        shopifyCartString,
        shopifyCartSignature,
        locale: this.locale,
      });

      if (this.getCustomerData) {
        this.getCustomerData()
          .then((data) => {
            if (data) {
              bridge.postMessage('PREFILL', {
                ...data,
                ...(data as any).address,
              });
            }
          })
          .catch((err) => {
            // biome-ignore lint/suspicious/noConsole: log
            console.error(err);
          });
      }
    });

    const checkoutIframe =
      document.querySelector<HTMLIFrameElement>('#checkout-iframe');
    if (checkoutIframe) {
      checkoutIframe.style.visibility = 'visible';
      checkoutIframe.style.background = 'white';
    }

    window.addEventListener('message', async (message) => {
      if (message.origin !== this.hostURL) {
        return;
      }

      const { meta, data, type } = message.data;
      if (meta?.type === 'remove-line-item') {
        for (const variantId of data.externalVariantIds) {
          await this.cartAdapter().decrementQuantity(variantId, id);
        }

        /**
         * TODO: Temporary solution to handle removed line items
         * where the cart is given to us with extra information we
         * cant process
         */
        window.location.reload();
      } else if (meta?.type === 'clear-cart') {
        await this.cartAdapter().clear(id);
      } else if (meta?.type === 'redirect-to-checkout') {
        window.location.href = this.routesAdapter().checkoutRoute();
      } else if (type === 'GO_TO_HOME_PAGE') {
        window.location.href = this.routesAdapter().homePageRoute();
      } else if (emitPublicEvents) {
        if (type === 'CHECKOUT_LOADED') {
          events.dispatch('CheckoutLoaded');
        } else if (type === 'PREORDER_CHECKOUT_STEP') {
          events.dispatch('PreorderCheckoutStep', data);
        } else if (type === 'PREORDER_CHECKOUT_SUBMITTED') {
          events.dispatch('PreorderCheckoutSubmitted', data);
        } else if (type === 'PREORDER_CREATED') {
          events.dispatch('PreorderCreated', data);
        } else if (type === 'ORDER_CREATED') {
          events.dispatch('OrderCreated', data);
        } else if (type === 'PREORDER_FAILED') {
          events.dispatch('PreorderFailed', data);
        }
      }
    });
  }

  private cartAdapter(): Cart<CartItem> {
    return (
      (window as any).PurpleDotConfig?.cartAdapter ?? new ShopifyAJAXCart()
    );
  }

  private routesAdapter() {
    return ShopifyRoutesAdapter;
  }
}
