import * as ParentPageSessionToken from '../../parent-page-session-token';

import trackEvent from '../../track-event';

class SimplePlacement {
  constructor({ placementType, instanceId }, iframe, src, resizeBehaviour) {
    this.placementType = placementType;
    this.instanceId = instanceId;
    this.iframe = iframe;
    this.src = src;
    this.resizeBehaviour = resizeBehaviour;
  }

  mountInto(container) {
    this.iframe.style((elem) => {
      elem.setAttribute('scrolling', 'no');
      elem.setAttribute('class', 'purple-dot-frame');
      elem.style.border = 'none';
      elem.style.height = '54px';

      this.resizeBehaviour.setInitialSize(elem);

      elem.dataset.placementType = this.placementType;
      elem.dataset.instanceId = this.instanceId;
    });

    this.iframe.attachListener([
      [
        'loaded',
        (d) => {
          this._onLoaded(d);
        },
      ],
      [
        'window-size-changed',
        (data) => {
          this._adjustSize(data);
        },
      ],
      [
        'failed',
        (d) => {
          this._onFailed(d);
        },
      ],
      [
        'session-token-request',
        () => {
          const parentPageSessionToken = ParentPageSessionToken.getOrCreate();
          this.iframe.postMessage('session-token-response', {
            parentPageSessionToken,
          });
        },
      ],
    ]);

    this.iframe.mountInto(container, this.src);
  }

  update(requestData) {
    const url = this.src.update(requestData);
    this.iframe.setSrc(url);
  }

  _onLoaded(data) {
    trackEvent('placement_loaded', {
      placementType: this.placementType,
      ...data,
    });
    this.iframe.style((elem) => {
      if (elem.style.display === 'none') {
        elem.style.display = '';
      }
    });
  }

  _onFailed(data) {
    trackEvent('placement_failed', {
      placementType: this.placementType,
      ...data,
    });
    this.iframe.style((elem) => {
      elem.style.display = 'none';
    });
  }

  _adjustSize({ width, height }) {
    this.iframe.style((elem) => {
      this.resizeBehaviour.adjustSize(elem, { width, height });
    });
  }
}

export default SimplePlacement;
