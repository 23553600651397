import 'fast-text-encoding';
import murmurhash from 'murmurhash';

function isTextValid(value: string | null | undefined) {
  return !!(value && value.length > 0);
}

function camelToKebabCase(s: string) {
  const words = s.replace(/[A-Z]/g, (ch) => `-${ch.toLowerCase()}`);
  return words;
}

function capitalCase(s: string) {
  const words = s
    .toLowerCase()
    .replace(/^.[*]?|\s.[*]?/g, (ch) => ch.toUpperCase());
  return words;
}

function generateRandomCode(len = 4) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const charactersLength = characters.length;

  for (let i = 1; i < len; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  result += Math.floor(Math.random() * 10);
  return result;
}

// Reimplementation of the Java hasCode function, useful for turning strings
// into unique integers. See https://gist.github.com/hyamamoto/fd435505d29ebfa3d9716fd2be8d42f0.
function hashCode(s: string) {
  let h = 0;
  for (let i = 0; i < s.length; i += 1) {
    h = (Math.imul(31, h) + s.charCodeAt(i)) >>> 0;
  }
  return h;
}

function normalizedHashCode(s: string) {
  const hash = murmurhash.v3(s);
  return hash / 0xffffffff; // Normalize to range [0,1]
}

function clip(s: string, len: number) {
  return s?.slice(0, len);
}

export {
  isTextValid,
  camelToKebabCase,
  capitalCase,
  generateRandomCode,
  hashCode,
  normalizedHashCode,
  clip,
};
