import { getPlacementContainer } from '@purple-dot/placement-iframe';

class LoadRequest {
  constructor(placementFactory, { placementType, instanceId, ...options }) {
    this.placementFactory = placementFactory;
    this.placementType = placementType;
    this.instanceId = instanceId;
    this.options = options;
  }

  placementId() {
    return this._id();
  }

  value() {
    return [this._container(), this._placement()];
  }

  _container() {
    if (!this.instanceId) {
      /** Bit of a hack where we need to default the instanceId or have it passed to us */

      // Find an element without an instanceId
      const elem = getPlacementContainer({ placementType: this.placementType });
      if (!elem) {
        return null;
      }

      // Default it to 1
      elem.dataset.instanceId = '1';
      this.instanceId = '1';
      return elem;
    }

    return getPlacementContainer({
      placementType: this.placementType,
      instanceId: this.instanceId,
    });
  }

  _placement() {
    const id = this._id();
    if (this.placementType === 'button') {
      const button = this.placementFactory.button(id, this._requestData());
      return button;
    }

    if (this.placementType === 'cart-button') {
      const cartButton = this.placementFactory.cartButton(
        id,
        this._requestData()
      );
      return cartButton;
    }

    const simple = this.placementFactory.simple(id, this._requestData());
    return simple;
  }

  _requestData() {
    return {
      placementType: this.placementType,
      instanceId: this.instanceId,
      ...this.options,
    };
  }

  _id() {
    return `${this.placementType}_${this.instanceId}`;
  }
}

export default LoadRequest;
